import { default as MSkeleton } from '@mui/material/Skeleton';
import { getListItemKey } from 'src/utils/format';

// ----------------------------------------------------------------------

export default function Skeleton({
  size = 1,
  width = '100%',
  height = 120,
  sx = {}
}: {
  size?: number;
  width?: number | string;
  height?: number | string;
  sx?: object;
}) {
  return (
    <div className="flex w-full flex-col">
      {Array(size)
        .fill(0, 0, size)
        .map((item, idx) => {
          return (
            <MSkeleton
              key={getListItemKey(item + idx)}
              variant="rounded"
              animation="pulse"
              width={width}
              height={height}
              sx={{
                backgroundColor: 'background.skeleton',
                mt: size > 0 && idx !== 0 ? 1 : 0,
                ...sx
              }}
            />
          );
        })}
    </div>
  );
}
